import { cn } from '@/lib/utils';
import { Paperclip } from 'lucide-react';

type AttachmentCountProps = {
  readonly value?: number | null;
};

const AttachmentCount = ({ value = 0 }: AttachmentCountProps) => {
  const count = value ?? 0;

  return (
    <div
      className={cn(
        'flex items-center',
        count > 0 ? '' : 'text-gray-400',
        'gap-2',
      )}
    >
      <Paperclip className="w-5 h-5" /> {value}
    </div>
  );
};

export { AttachmentCount, type AttachmentCountProps };
