import { Alert, AlertDescription } from '@/components/ui/alert';
import { Button } from '@/components/ui/button';
import { graphql } from '@/gql';
import { useAppStore } from '@/stores';
import { Info } from 'lucide-react';
import { Link } from 'react-router-dom';
import { useQuery } from 'urql';

const MembershipRequestCalloutGql = graphql(`
  query MembershipRequestCalloutGql($organizationId: String!) {
    organization(id: $organizationId) {
      id
      membershipRequests {
        totalCount
      }
    }
  }
`);

type MembershipRequestCalloutProps = {
  readonly className: string;
  readonly organizationId: string;
};

const MembershipRequestCallout = ({
  className,
  organizationId,
}: MembershipRequestCalloutProps) => {
  const isAdmin = useAppStore((state) => state.activeMembership?.isAdmin);

  const [{ data }] = useQuery({
    pause: !isAdmin,
    query: MembershipRequestCalloutGql,
    variables: {
      organizationId,
    },
  });

  if (!data?.organization) {
    return null;
  }

  if (data.organization.membershipRequests.totalCount === 0) {
    return null;
  }

  return (
    <Alert
      className={className}
      variant="info"
    >
      <div className="flex items-center justify-between">
        <div className="flex items-center gap-2">
          <Info className="h-6 w-6" />
          <AlertDescription className="text-base">
            <div>
              You have {data.organization.membershipRequests.totalCount}{' '}
              membership
              {data.organization.membershipRequests.totalCount > 1
                ? ' requests '
                : ' request '}
              that
              {data.organization.membershipRequests.totalCount > 1
                ? ' require '
                : ' requires '}
              action.
            </div>
          </AlertDescription>
        </div>
        <Link to="/members">
          <Button
            className="h-8"
            variant="secondary"
          >
            View
          </Button>
        </Link>
      </div>
    </Alert>
  );
};

export { MembershipRequestCallout };
