import { Datetime } from '@/components/Datetime';
import { AdvancedMarker, InfoWindow, Pin } from '@/components/Map';
import { Avatar, AvatarFallback, AvatarImage } from '@/components/ui/avatar';
import { type CheckIn, type Member } from '@/gql/graphql';
import { Info, X } from 'lucide-react';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

type Props = Pick<CheckIn, 'id' | 'shortId' | 'createdAt'> & {
  readonly member?: Pick<
    Member,
    'fullName' | 'displayName' | 'avatarUrl'
  > | null;
  readonly popsition: {
    lat: number;
    lng: number;
  };
  readonly zIndex: number;
};
const CheckInMapMarker = ({
  createdAt,
  id,
  member,
  popsition,
  shortId,
  zIndex,
}: Props) => {
  const navigate = useNavigate();

  const [infowindowShown, setInfowindowShown] = useState(false);

  const toggleInfoWindow = () =>
    setInfowindowShown((previousState) => !previousState);

  const closeInfoWindow = () => setInfowindowShown(false);

  const name = member?.displayName ?? member?.fullName ?? 'Deleted Member';

  return (
    <AdvancedMarker
      onClick={toggleInfoWindow}
      position={popsition}
      zIndex={infowindowShown ? zIndex * 1_000 : zIndex}
    >
      {infowindowShown ? (
        <InfoWindow
          headerDisabled
          onCloseClick={closeInfoWindow}
          position={popsition}
        >
          <div className="transition-all ease-in-out flex items-center h-8 bg-none h-full text-black text-base">
            <div className="space-y-2">
              <div className="w-full flex items-center justify-between gap-4">
                <div className="font-semibold">Check In # {shortId}</div>
                <div>
                  <button
                    className="h-6 w-6 transition-all ease-in-out text-gray-300 hover:text-black hover:bg-gray-100 flex items-center justify-center rounded-full"
                    onClick={closeInfoWindow}
                    type="button"
                  >
                    <X className="h-4 w-4" />
                  </button>
                </div>
              </div>

              <div className="w-full flex items-center justify-between gap-4">
                <div className="mt-0.5 flex items-center gap-2 text-sm">
                  <Avatar className="h-8 w-8">
                    <AvatarImage
                      alt={name}
                      src={member?.avatarUrl ?? undefined}
                    />
                    <AvatarFallback>{name.slice(0, 2)}</AvatarFallback>
                  </Avatar>
                  <div className="flex flex-col">
                    <div>{name}</div>
                    <div>
                      <Datetime
                        datetime={createdAt}
                        format="MM/dd/yy, hh:mm aaa"
                      />
                    </div>
                  </div>
                </div>
                <div>
                  <button
                    className="h-12 w-12 transition-all ease-in-out hover:bg-gray-100 flex items-center justify-center rounded-full"
                    onClick={() => {
                      navigate(`/check-in/${id}`);
                    }}
                    type="button"
                  >
                    <Info className="h-8 w-8" />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </InfoWindow>
      ) : (
        <Pin size="avatar">
          <Avatar className="h-8 w-8">
            <AvatarImage
              alt={name}
              src={member?.avatarUrl ?? undefined}
            />
            <AvatarFallback>{name.slice(0, 2)}</AvatarFallback>
          </Avatar>
        </Pin>
      )}
    </AdvancedMarker>
  );
};

export { CheckInMapMarker };
