import { JoinOrganization } from '../../Account';
import { ActiveGuardMeSessions } from '../components/ActiveGuardMeSessions';
import { CheckInMap } from '../components/CheckInMap';
import { IncidentsAssignedToMe } from '../components/IncidentsAssignedToMe';
import { MembershipRequestCallout } from '../components/MembershipRequestCallout';
import { faq } from '../components/NewUserFrequentlyAskedQuestions';
import { Recent } from '../components/Recent';
import { MapProvider } from '@/components/Map';
import { Button } from '@/components/ui/button';
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from '@/components/ui/card';
import { Tabs, TabsContent, TabsList, TabsTrigger } from '@/components/ui/tabs';
import { useAppStore } from '@/stores';
import { Link } from 'react-router-dom';

const Home = () => {
  const activeMembership = useAppStore((state) => state.activeMembership);

  if (!activeMembership) {
    return (
      <div className="grid items-start gap-4 p-4 sm:px-6 sm:py-0 md:gap-8">
        <div>
          <h1 className="font-bold text-3xl lg:text-5xl">
            Welcome to See It Send It
          </h1>
          <p className="mt-6 max-w-prose text-xl text-muted-foreground">
            Empower your organization to protect the safety of your people and
            business. Get started with your own organization or join one already
            setup for you.
          </p>
        </div>

        <Tabs
          className="w-full"
          defaultValue="create"
        >
          <TabsList className="grid w-full grid-cols-2">
            <TabsTrigger
              className="cursor-pointer"
              value="create"
            >
              Create an Organization
            </TabsTrigger>
            <TabsTrigger
              className="cursor-pointer"
              value="join"
            >
              Join an Organization
            </TabsTrigger>
          </TabsList>

          <TabsContent value="create">
            <Card>
              <CardHeader>
                <CardTitle>Create an Organization</CardTitle>
                <CardDescription>
                  Unite teams and enhance safety with See It Send It.
                </CardDescription>
              </CardHeader>
              <CardContent className="space-y-4">
                <div className="flex justify-center">
                  <Link to="/organization/new">
                    <Button
                      className="px-8"
                      size="lg"
                    >
                      Get Started
                    </Button>
                  </Link>
                </div>

                {faq.map((item) => (
                  <div key={item.id}>
                    <p className="font-semibold">{item.question}</p>
                    {item.answer.map((answer, index) => (
                      <p
                        className="leading-6 mb-2"
                        // eslint-disable-next-line react/no-array-index-key
                        key={`${answer}-${index}`}
                      >
                        {answer}
                      </p>
                    ))}
                  </div>
                ))}
              </CardContent>
            </Card>
          </TabsContent>

          <TabsContent value="join">
            <Card>
              <CardHeader>
                <CardTitle>Join an Organization</CardTitle>
                <CardDescription>
                  Use a Membership Code to request access to an organization.
                </CardDescription>
              </CardHeader>
              <CardContent>
                <JoinOrganization />
              </CardContent>
            </Card>
          </TabsContent>
        </Tabs>
      </div>
    );
  }

  return (
    <div className="grid grid-cols-1 items-start gap-4 p-4 sm:px-6 sm:py-0 md:gap-6 lg:grid-cols-2 xl:grid-cols-5 mb-4">
      <MembershipRequestCallout
        className="lg:col-span-full"
        organizationId={activeMembership.id}
      />

      <MapProvider>
        <CheckInMap
          className="h-full xl:col-span-3"
          defaultMapCenter={activeMembership.defaultMapCenter}
          defaultMapZoom={activeMembership.defaultMapZoom}
          organizationId={activeMembership.id}
        />
      </MapProvider>

      <Recent
        className="h-full xl:col-span-2"
        organizationId={activeMembership.id}
        organizationName={activeMembership.name}
      />

      <ActiveGuardMeSessions
        className="lg:col-span-full"
        organizationId={activeMembership.id}
      />

      <IncidentsAssignedToMe
        className="lg:col-span-full"
        memberId={activeMembership.memberId}
        organizationId={activeMembership.id}
      />
    </div>
  );
};

export { Home };
