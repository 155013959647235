import { CheckInMapMarker } from './CheckInMapMarker';
import { Loader } from '@/components/Loader';
import { Map, useMap } from '@/components/Map';
import { Card, CardContent, CardHeader, CardTitle } from '@/components/ui/card';
import { graphql } from '@/gql';
import { sub } from 'date-fns';
import { useEffect, useState } from 'react';
import { useQuery } from 'urql';

const CheckInMapGql = graphql(`
  query CheckInMapGql($organizationId: String!, $createdAt_gte: Datetime!) {
    checkIns(
      condition: {
        organizationId: $organizationId
        createdAt: { gte: $createdAt_gte }
      }
    ) {
      nodes {
        id
        shortId
        member {
          id
          fullName
          displayName
          avatarUrl
        }
        createdAt
        coordinates {
          x
          y
        }
      }
    }
  }
`);

type CheckInMapProps = {
  readonly className: string;
  readonly defaultMapCenter: { latitude: number; longitude: number };
  readonly defaultMapZoom: number;
  readonly organizationId: string;
};

const CheckInMap = ({
  className,
  defaultMapCenter,
  defaultMapZoom,
  organizationId,
}: CheckInMapProps) => {
  const [interval, setInterval] = useState<24 | 48>(24);
  const [createdAtGte, setCreatedAtGte] = useState(
    sub(new Date(), { hours: interval }),
  );

  const map = useMap();

  const [{ data, error }] = useQuery({
    query: CheckInMapGql,
    variables: {
      createdAt_gte: createdAtGte.toISOString(),
      organizationId,
    },
  });

  // update map if the defaultCenter changes
  useEffect(() => {
    if (map) {
      map.setCenter({
        lat: defaultMapCenter.latitude,
        lng: defaultMapCenter.longitude,
      });
      map.setZoom(defaultMapZoom);
    }
  }, [defaultMapCenter, defaultMapZoom, map]);

  return (
    <Card className={className}>
      <CardHeader className="flex flex-row items-end justify-between">
        <CardTitle>Check In's</CardTitle>
        <div className="flex gap-1">
          <button
            className={
              interval === 24
                ? undefined
                : 'text-muted-foreground/50 hover:underline'
            }
            disabled={interval === 24}
            onClick={(event) => {
              event.preventDefault();
              setInterval(24);
              setCreatedAtGte(sub(new Date(), { hours: 24 }));
            }}
            type="button"
          >
            24h
          </button>
          <span className="text-muted-foreground/75">|</span>
          <button
            className={
              interval === 48
                ? undefined
                : 'text-muted-foreground/50 hover:underline'
            }
            disabled={interval === 48}
            onClick={(event) => {
              event.preventDefault();
              setInterval(48);
              setCreatedAtGte(sub(new Date(), { hours: 48 }));
            }}
            type="button"
          >
            48h
          </button>
        </div>
      </CardHeader>
      <CardContent>
        <Loader loadingError={error} />
        <Map
          className="h-80"
          defaultCenter={{
            lat: defaultMapCenter.latitude,
            lng: defaultMapCenter.longitude,
          }}
          defaultZoom={defaultMapZoom}
        >
          {data?.checkIns?.nodes.map((item, index) => (
            <CheckInMapMarker
              createdAt={item.createdAt}
              id={item.id}
              key={item.id}
              member={item.member}
              popsition={{ lat: item.coordinates.y, lng: item.coordinates.x }}
              shortId={item.shortId}
              zIndex={100 + index}
            />
          ))}
        </Map>
      </CardContent>
    </Card>
  );
};

export { CheckInMap };
