import { Spinner } from './components';
import { config } from './config';
import { VersionUpdateCheck } from './features/Misc';
import { ThemeProvider } from './providers/ThemeProvider';
import { appRoutes } from './routes';
import * as Sentry from '@sentry/react';
import { useEffect } from 'react';
import { HelmetProvider } from 'react-helmet-async';
import {
  createBrowserRouter,
  createRoutesFromChildren,
  matchRoutes,
  RouterProvider,
  useLocation,
  useNavigationType,
} from 'react-router-dom';

Sentry.init({
  dsn: config.SENTRY_DSN,
  environment: config.STAGE,
  integrations: [
    Sentry.reactRouterV6BrowserTracingIntegration({
      createRoutesFromChildren,
      matchRoutes,
      useEffect,
      useLocation,
      useNavigationType,
    }),
    Sentry.replayIntegration(),
  ],
  release: config.VERSION,
  // Capture Replay for 10% of all sessions,
  // plus for 100% of sessions with an error
  replaysOnErrorSampleRate: 1,
  replaysSessionSampleRate: 0.1,
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for tracing.
  tracesSampleRate: 1,
});

const sentryCreateBrowserRouter =
  Sentry.wrapCreateBrowserRouter(createBrowserRouter);

const router = sentryCreateBrowserRouter(appRoutes);

const App = () => {
  return (
    <>
      <VersionUpdateCheck />
      <HelmetProvider>
        <ThemeProvider
          defaultTheme="light"
          storageKey="see-it-send-it-theme"
        >
          <RouterProvider
            fallbackElement={
              <Spinner
                fullscreen
                logo
              />
            }
            router={router}
          />
        </ThemeProvider>
      </HelmetProvider>
    </>
  );
};

export { App };
