import { ThemeToggleDropdown } from '../ThemeToggleDropdown';
import { Button } from '@/components/ui/button';
import { cn } from '@/lib/utils';
import { ChevronsLeft } from 'lucide-react';
import { Link, useLocation } from 'react-router-dom';

const links = [
  {
    title: 'Overview',
    to: '/administration/overview',
  },
  {
    title: 'Plans',
    to: '/administration/plans',
  },
  {
    title: 'Organizations',
    to: '/administration/organizations',
  },
  {
    title: 'Users',
    to: '/administration/users',
  },
  {
    title: 'Worker Tasks',
    to: '/administration/worker-tasks',
  },
  {
    title: 'Incident Types',
    to: '/administration/incident-types',
  },
  {
    title: 'Bivy Sticks',
    to: '/administration/bivy-sticks',
  },
  {
    title: 'Client Tools',
    to: '/administration/client-simulation',
  },
  {
    title: 'Colors',
    to: '/administration/colors',
  },
];

const AdministrationHeader = () => {
  const pathname = useLocation().pathname;

  return (
    <div className="h-14 flex items-center">
      <Link to="/">
        <Button
          className="h-10 rounded-0"
          variant="outline"
        >
          <ChevronsLeft className="h-4 w-4 mr-2" />
          Exit
        </Button>
      </Link>

      <nav className="ml-2 inline-flex h-10 items-center justify-center rounded-md p-1 text-muted-foreground">
        {links.map((item) => (
          <Link
            className={cn(
              'inline-flex items-center justify-center whitespace-nowrap rounded-xl px-3 py-1.5 text-sm font-medium ring-offset-background transition-all focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50',
              pathname === item.to
                ? 'bg-primary text-primary-foreground shadow-sm'
                : 'hover:text-primary hover:shadow-sm',
            )}
            key={item.to}
            to={item.to}
          >
            {item.title}
          </Link>
        ))}
      </nav>

      <span className="ml-auto mr-4">
        <ThemeToggleDropdown />
      </span>
    </div>
  );
};

export { AdministrationHeader };
