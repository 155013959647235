import { CheckInUpdates } from '@/features/CheckIn/components/CheckInUpdates';
import { GuardMeUpdates } from '@/features/GuardMe/components/GuardMeUpdates';
import { IncidentsUpdates } from '@/features/Incidents/components/IncidentsUpdates';
import { useAppStore } from '@/stores';
import { Fragment, type ReactNode } from 'react';

type CurrentOrganizationProps = {
  readonly children: ReactNode;
};

const CurrentOrganization = ({ children }: CurrentOrganizationProps) => {
  const memberships = useAppStore((state) => state.memberships);

  return (
    <>
      {memberships.map((item) => (
        <Fragment key={item.id}>
          <GuardMeUpdates organizationId={item.id} />
          <IncidentsUpdates organizationId={item.id} />
          <CheckInUpdates organizationId={item.id} />
        </Fragment>
      ))}
      {children}
    </>
  );
};

export { CurrentOrganization };
