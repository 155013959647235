import { Loader } from '@/components/Loader';
import { Avatar, AvatarFallback, AvatarImage } from '@/components/ui/avatar';
import { Card, CardHeader, CardTitle } from '@/components/ui/card';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '@/components/ui/table';
import { TableRowSkeleton } from '@/components/ui/TableWrapper';
import { AttachmentCount } from '@/features/GuardMe/components/AttachmentCount';
import { BatteryLevel } from '@/features/GuardMe/components/BatteryLevel';
import { GuardMeStatus } from '@/features/GuardMe/components/GuardMeStatus';
import { GuardMeTimer } from '@/features/GuardMe/components/GuardMeTimer';
import { graphql } from '@/gql';
import { GuardmeStatus } from '@/gql/graphql';
import { fmtPhoneNumber } from '@/utils/formatters';
import { Link, useNavigate } from 'react-router-dom';
import { useQuery } from 'urql';

const ActiveGuardMeSessionsGql = graphql(`
  query ActiveGuardMeSessionsGql($organizationId: String!) {
    guardmes(
      condition: {
        organizationId: $organizationId
        status: {
          concluded: false
          expired: true
          inPanic: true
          inProgress: true
        }
      }
    ) {
      nodes {
        id
        shortId
        memberId
        member {
          id
          avatarUrl
          displayName
          fullName
          phoneNumber
        }
        status
        createdAt
        endsAt
        updatedAt
        batteryLevel
        totalAttachments
      }
    }
    organization(id: $organizationId) {
      id
      settingIsRapidsosOperatorFlowEnabled
      settingIsRapidsosPanicButtonFlowEnabled
      settingRapidsosSocPhoneNumber
    }
  }
`);

type ActiveGuardMeSessionsProps = {
  readonly className: string;
  readonly organizationId: string;
};

const ActiveGuardMeSessions = ({
  className,
  organizationId,
}: ActiveGuardMeSessionsProps) => {
  const navigate = useNavigate();

  const [{ data, error, fetching }] = useQuery({
    query: ActiveGuardMeSessionsGql,
    variables: {
      organizationId,
    },
  });

  const handleRowClick = (entityId: string) => {
    navigate(`/guardme/${entityId}`);
  };

  return (
    <Card className={className}>
      <CardHeader>
        <CardTitle>Active GuardMe sessions</CardTitle>
      </CardHeader>

      <Loader loadingError={error}>
        <Table>
          <TableHeader>
            <TableRow>
              <TableHead className="pl-6">Id</TableHead>
              <TableHead>Status</TableHead>
              <TableHead>Ends In</TableHead>
              <TableHead>Member</TableHead>
              <TableHead>Phone Number</TableHead>
              <TableHead>Battery</TableHead>
              <TableHead>Attachments</TableHead>
            </TableRow>
          </TableHeader>
          <TableBody>
            {fetching && !data && (
              <TableRowSkeleton
                cols={7}
                rows={2}
              />
            )}
            {!fetching && data?.guardmes?.nodes.length === 0 && (
              <TableRow className="last:border-none hover:bg-inherit">
                <TableCell
                  className="text-center h-24"
                  colSpan={7}
                >
                  No Active Sessions.
                </TableCell>
              </TableRow>
            )}
            {!fetching &&
              data?.guardmes?.nodes.map((item) => (
                <TableRow
                  className="last:border-none last:rounded-b-lg"
                  key={item.id}
                  onClick={() => handleRowClick(item.id)}
                >
                  <TableCell className="pl-6">
                    <Link
                      className="whitespace-nowrap font-bold hover:underline"
                      to={`/guardme/${item.id}`}
                    >
                      {item.shortId}
                    </Link>
                  </TableCell>
                  <TableCell>
                    <GuardMeStatus status={item.status} />
                  </TableCell>
                  <TableCell>
                    <div className="w-[12ch]">
                      {item.status !== GuardmeStatus.InPanic && (
                        <GuardMeTimer targetTime={item.endsAt} />
                      )}
                    </div>
                  </TableCell>
                  <TableCell>
                    <div className="flex items-center gap-2">
                      <Avatar className="h-6 w-6">
                        <AvatarImage
                          src={item.member?.avatarUrl ?? undefined}
                        />
                        <AvatarFallback>
                          {item.member?.fullName.slice(0, 2)}
                        </AvatarFallback>
                      </Avatar>
                      <div>
                        <p className="text-sm font-medium leading-none">
                          {item.member?.displayName ?? item.member?.fullName}
                        </p>
                      </div>
                    </div>
                  </TableCell>
                  <TableCell>
                    <a
                      className="block hover:underline"
                      href={`tel:${item.member?.phoneNumber}`}
                    >
                      {fmtPhoneNumber(item.member?.phoneNumber)}
                    </a>
                  </TableCell>
                  <TableCell>
                    <BatteryLevel value={item.batteryLevel} />
                  </TableCell>
                  <TableCell>
                    <AttachmentCount value={item.totalAttachments} />
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </Loader>
    </Card>
  );
};

export { ActiveGuardMeSessions };
