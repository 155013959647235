import { formatTimer, toDatetime } from '@/lib/time';
import { useEffect, useState } from 'react';
import { twMerge } from 'tailwind-merge';

type Props = {
  readonly startTime?: Date | string;
  readonly targetTime?: Date | string;
};

const GuardMeTimer = ({ startTime, targetTime }: Props) => {
  const [currentTime, setCurrentTime] = useState<Date>(new Date());

  useEffect(() => {
    setCurrentTime(new Date());
  }, [startTime, targetTime]);

  useEffect(() => {
    const countdownInterval = setInterval(() => {
      setCurrentTime(new Date());
    }, 1_000);
    return () => {
      clearInterval(countdownInterval);
    };
  });

  if (startTime) {
    return (
      <span>
        {formatTimer({
          end: currentTime,
          start: startTime,
        })}
      </span>
    );
  }

  if (targetTime) {
    const internalTime = toDatetime(targetTime);

    const past = internalTime.getTime() < currentTime.getTime();
    const soon =
      !past && internalTime.getTime() < currentTime.getTime() + 1_000 * 300;

    const classes = twMerge(soon && 'text-yellow-600', past && 'text-red-600');

    return (
      <span className={classes}>
        {past && '- '}
        {formatTimer({
          end: internalTime,
          start: currentTime,
        })}
      </span>
    );
  }

  return <span>---</span>;
};

export { GuardMeTimer };
