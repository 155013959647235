import { ChangeIncidentPriorityMenu } from './ChangeIncidentPriorityMenu';
import { ChangeIncidentStatusMenu } from './ChangeIncidentStatusMenu';
import { UnassignedIncidentsNotification } from './UnassignedIncidentsNotification';
import { Loader } from '@/components/Loader';
import { Badge } from '@/components/ui/badge';
import { Card, CardHeader, CardTitle } from '@/components/ui/card';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '@/components/ui/table';
import { TableRowSkeleton } from '@/components/ui/TableWrapper';
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from '@/components/ui/tooltip';
import { graphql } from '@/gql';
import { Link, useNavigate } from 'react-router-dom';
import { useQuery } from 'urql';

const IncidentsAssignedToMeGql = graphql(`
  query IncidentsAssignedToMeGql($organizationId: String!, $memberId: String!) {
    incidents(
      condition: {
        organizationId: $organizationId
        ownedByMemberId: $memberId
        status: { closed: false, open: true, inProgress: true }
      }
    ) {
      nodes {
        id
        shortId
        subject
        priority
        contactMemberId
        contactMember {
          id
          fullName
          displayName
        }
        incidentType {
          id
          name
          iconSvg
        }
        status
        updatedAt
      }
    }
  }
`);

type IncidentsAssignedToMeProps = {
  readonly className?: string;
  readonly memberId: string;
  readonly organizationId: string;
};

const IncidentsAssignedToMe = ({
  className,
  memberId,
  organizationId,
}: IncidentsAssignedToMeProps) => {
  const navigate = useNavigate();

  const [{ data, error, fetching }] = useQuery({
    query: IncidentsAssignedToMeGql,
    variables: {
      memberId,
      organizationId,
    },
  });

  const handleRowClick = (entityId: string) => {
    navigate(`/incidents/${entityId}`);
  };

  return (
    <Card className={className}>
      <CardHeader>
        <CardTitle className="flex flex-col md:flex-row md:items-center gap-2">
          Incidents assigned to me
          <UnassignedIncidentsNotification organizationId={organizationId} />
        </CardTitle>
      </CardHeader>

      <Loader loadingError={error}>
        <Table>
          <TableHeader>
            <TableRow>
              <TableHead className="pl-6">Id</TableHead>
              <TableHead>Subject</TableHead>
              <TableHead>Status</TableHead>
              <TableHead>Priority</TableHead>
            </TableRow>
          </TableHeader>
          <TableBody>
            {fetching && !data && (
              <TableRowSkeleton
                cols={4}
                rows={2}
              />
            )}
            {!fetching && data?.incidents?.nodes.length === 0 && (
              <TableRow className="last:border-none hover:bg-inherit">
                <TableCell
                  className="text-center h-24"
                  colSpan={4}
                >
                  No Incidents Assigned.
                </TableCell>
              </TableRow>
            )}
            {!fetching &&
              data?.incidents?.nodes.map((item) => (
                <TableRow
                  className="last:border-none last:rounded-b-lg"
                  key={item.id}
                  onClick={() => handleRowClick(item.id)}
                >
                  <TableCell className="pl-6">
                    <Link
                      className="whitespace-nowrap font-bold hover:underline"
                      to={`/incidents/${item.id}`}
                    >
                      {item.shortId}
                    </Link>
                  </TableCell>
                  <TableCell>
                    <div className="flex items-center">
                      <Badge
                        className="mr-2"
                        variant="secondary"
                      >
                        <img
                          alt={item.incidentType?.name}
                          className="h-4 w-4 mr-2"
                          src={`data:image/svg+xml;base64,${item.incidentType?.iconSvg}`}
                        />
                        {item.incidentType?.name}
                      </Badge>
                      <Tooltip>
                        <TooltipTrigger asChild>
                          <span className="font-bold overflow-hidden truncate max-w-20 lg:max-w-72 xl:max-w-md 2xl:max-w-lg">
                            {item.subject}
                          </span>
                        </TooltipTrigger>
                        <TooltipContent>
                          <p>{item.subject}</p>
                        </TooltipContent>
                      </Tooltip>
                    </div>
                  </TableCell>
                  <TableCell>
                    <ChangeIncidentStatusMenu
                      incidentId={item.id}
                      status={item.status}
                    />
                  </TableCell>
                  <TableCell>
                    <ChangeIncidentPriorityMenu
                      incidentId={item.id}
                      priority={item.priority}
                    />
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </Loader>
    </Card>
  );
};

export { IncidentsAssignedToMe };
