import { GuardmeStatus } from '@/gql/graphql';
import { cn } from '@/lib/utils';
import { Check, CircleAlert, Play, ShieldAlert } from 'lucide-react';

type GuardMeStatusProps = {
  readonly className?: string;
  readonly size?: 'md' | 'lg';
  readonly status?: GuardmeStatus;
};

const GuardMeStatus = ({
  className,
  size = 'md',
  status,
}: GuardMeStatusProps) => {
  if (!status) {
    return null;
  }

  let classes = '';
  let icon = Check;
  let label = '';

  if (status === GuardmeStatus.Concluded) {
    classes = 'bg-secondary text-secondary-foreground';
    icon = Check;
    label = 'Concluded';
  } else if (status === GuardmeStatus.Expired) {
    classes = 'bg-warning text-warning-foreground';
    icon = CircleAlert;
    label = 'Expired';
  } else if (status === GuardmeStatus.InPanic) {
    classes = 'bg-destructive text-destructive-foreground';
    icon = ShieldAlert;
    label = 'In Panic';
  } else if (status === GuardmeStatus.InProgress) {
    classes = 'bg-info text-info-foreground';
    icon = Play;
    label = 'In Progress';
  }

  const Icon = icon;

  return (
    <div
      className={cn(
        `rounded-md py-1 px-2 text-sm leading-1 inline-flex items-center gap-2`,
        size === 'lg' && 'text-lg',
        classes,
        className,
      )}
    >
      <Icon className={cn('h-4 w-4', size === 'lg' && 'h-6 w-6')} />
      <span className="whitespace-nowrap">{label}</span>
    </div>
  );
};

export { GuardMeStatus, type GuardMeStatusProps };
