import { Badge } from '@/components/ui/badge';
import { graphql } from '@/gql';
import { Link } from 'react-router-dom';
import { useQuery } from 'urql';

const UnassignedIncidentsNotificationGql = graphql(`
  query UnassignedIncidentsNotificationGql($organizationId: String!) {
    incidents(
      condition: {
        organizationId: $organizationId
        ownedByMemberId: null
        status: { closed: false, open: true, inProgress: true }
      }
    ) {
      totalCount
    }
  }
`);

type UnassignedIncidentsNotificationProps = {
  organizationId: string;
};

const UnassignedIncidentsNotification = ({
  organizationId,
}: UnassignedIncidentsNotificationProps) => {
  const [{ data }] = useQuery({
    query: UnassignedIncidentsNotificationGql,
    variables: {
      organizationId,
    },
  });

  if (!data?.incidents) {
    return null;
  }

  if (data.incidents.totalCount === 0) {
    return null;
  }

  return (
    <Link to="/incidents">
      <Badge variant="secondary">
        {data.incidents.totalCount} Unassigned Incidents
      </Badge>
    </Link>
  );
};

export { UnassignedIncidentsNotification };
