import { NotificationsMenuItem } from './NotificationsMenuItem';
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from '@/components/ui/tooltip';
import { AccountMenu } from '@/features/Account/components/AccountMenu';
import { VersionCheck } from '@/features/Misc/components/VersionCheck';
import { cn } from '@/lib/utils';
import { useAppStore } from '@/stores';
import {
  Flag,
  Home,
  Map,
  Pin,
  Settings,
  ShieldBan,
  ShieldCheck,
  Users,
} from 'lucide-react';
import { Link, useLocation } from 'react-router-dom';

const ApplicationSidebar = () => {
  const [
    isGlobalAdmin,
    organizationId,
    isOrganizationAdmin,
    isCheckInSupervisor,
  ] = useAppStore((store) => [
    store.isGlobalAdmin,
    store.activeMembership?.id,
    store.activeMembership?.isAdmin,
    store.activeMembership?.isCheckInSupervisor,
  ]);

  const pathname = useLocation().pathname;

  return (
    <aside className="fixed inset-y-0 left-0 z-10 hidden w-14 flex-col border-r bg-background md:flex">
      <nav className="flex flex-col items-center gap-4 px-2 sm:py-5">
        <AccountMenu
          className="h-9 w-9 md:h-8 md:w-8"
          isCollapsed
        />

        <NotificationsMenuItem />

        <Tooltip>
          <TooltipTrigger asChild>
            <Link
              className={cn(
                'flex h-9 w-9 items-center justify-center rounded-lg transition-colors md:h-8 md:w-8',
                pathname === '/home'
                  ? 'bg-primary text-primary-foreground'
                  : 'text-muted-foreground hover:text-foreground',
              )}
              to="/home"
            >
              <Home className="h-5 w-5" />
              <span className="sr-only">Dashboard</span>
            </Link>
          </TooltipTrigger>
          <TooltipContent side="right">Dashboard</TooltipContent>
        </Tooltip>
        {organizationId && (
          <>
            <Tooltip>
              <TooltipTrigger asChild>
                <Link
                  className={cn(
                    'flex h-9 w-9 items-center justify-center rounded-lg transition-colors md:h-8 md:w-8',
                    pathname === '/guardme'
                      ? 'bg-primary text-primary-foreground'
                      : 'text-muted-foreground hover:text-foreground',
                  )}
                  to="/guardme"
                >
                  <ShieldCheck className="h-5 w-5" />
                  <span className="sr-only">GuardMe</span>
                </Link>
              </TooltipTrigger>
              <TooltipContent side="right">GuardMe</TooltipContent>
            </Tooltip>

            <Tooltip>
              <TooltipTrigger asChild>
                <Link
                  className={cn(
                    'flex h-9 w-9 items-center justify-center rounded-lg transition-colors md:h-8 md:w-8',
                    pathname === '/incidents'
                      ? 'bg-primary text-primary-foreground'
                      : 'text-muted-foreground hover:text-foreground',
                  )}
                  to="/incidents"
                >
                  <Flag className="h-5 w-5" />
                  <span className="sr-only">Incidents</span>
                </Link>
              </TooltipTrigger>
              <TooltipContent side="right">Incidents</TooltipContent>
            </Tooltip>

            <Tooltip>
              <TooltipTrigger asChild>
                <Link
                  className={cn(
                    'flex h-9 w-9 items-center justify-center rounded-lg transition-colors md:h-8 md:w-8',
                    pathname === '/check-in'
                      ? 'bg-primary text-primary-foreground'
                      : 'text-muted-foreground hover:text-foreground',
                  )}
                  to="/check-in"
                >
                  <Pin className="h-5 w-5" />
                  <span className="sr-only">Check In</span>
                </Link>
              </TooltipTrigger>
              <TooltipContent side="right">Check In</TooltipContent>
            </Tooltip>

            {isCheckInSupervisor && (
              <Tooltip>
                <TooltipTrigger asChild>
                  <Link
                    className={cn(
                      'flex h-9 w-9 items-center justify-center rounded-lg transition-colors md:h-8 md:w-8',
                      pathname === '/check-in-reports'
                        ? 'bg-primary text-primary-foreground'
                        : 'text-muted-foreground hover:text-foreground',
                    )}
                    to="/check-in-reports"
                  >
                    <Map className="h-5 w-5" />
                    <span className="sr-only">Check In Reports</span>
                  </Link>
                </TooltipTrigger>
                <TooltipContent side="right">Check In Reports</TooltipContent>
              </Tooltip>
            )}

            <Tooltip>
              <TooltipTrigger asChild>
                <Link
                  className={cn(
                    'flex h-9 w-9 items-center justify-center rounded-lg transition-colors md:h-8 md:w-8',
                    pathname === '/members'
                      ? 'bg-primary text-primary-foreground'
                      : 'text-muted-foreground hover:text-foreground',
                  )}
                  to="/members"
                >
                  <Users className="h-5 w-5" />
                  <span className="sr-only">Members</span>
                </Link>
              </TooltipTrigger>
              <TooltipContent side="right">Members</TooltipContent>
            </Tooltip>

            {isOrganizationAdmin && (
              <Tooltip>
                <TooltipTrigger asChild>
                  <Link
                    className={cn(
                      'flex h-9 w-9 items-center justify-center rounded-lg transition-colors md:h-8 md:w-8',
                      pathname.startsWith('/settings')
                        ? 'bg-primary text-primary-foreground'
                        : 'text-muted-foreground hover:text-foreground',
                    )}
                    to="/settings"
                  >
                    <Settings className="h-5 w-5" />
                    <span className="sr-only">Settings</span>
                  </Link>
                </TooltipTrigger>
                <TooltipContent side="right">Settings</TooltipContent>
              </Tooltip>
            )}
          </>
        )}
      </nav>

      <nav className="mt-auto flex flex-col items-center gap-4 px-2 sm:py-5">
        <VersionCheck isCollapsed />

        {isGlobalAdmin && (
          <Tooltip>
            <TooltipTrigger asChild>
              <Link
                className={cn(
                  'flex h-9 w-9 items-center justify-center rounded-lg transition-colors md:h-8 md:w-8',
                  pathname === '/administration'
                    ? 'bg-primary text-primary-foreground'
                    : 'text-muted-foreground hover:text-foreground',
                )}
                to="/administration"
              >
                <ShieldBan className="h-5 w-5" />
                <span className="sr-only">Administration</span>
              </Link>
            </TooltipTrigger>
            <TooltipContent side="right">Administration</TooltipContent>
          </Tooltip>
        )}
      </nav>
    </aside>
  );
};

export { ApplicationSidebar };
