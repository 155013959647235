import { Button } from '@/components/ui/button';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu';
import { IncidentStatusMenuRadioGroup } from '@/features/Incidents';
import { IncidentStatus } from '@/features/Incidents/components/IncidentStatus';
import { type IncidentStatusType } from '@/gql/graphql';
import { cn } from '@/lib/utils';
import { ChevronDown } from 'lucide-react';
import { useState } from 'react';

type ChangeIncidentStatusMenuProps = {
  readonly incidentId: string;
  readonly status: IncidentStatusType;
};

const ChangeIncidentStatusMenu = ({
  incidentId,
  status,
}: ChangeIncidentStatusMenuProps) => {
  const [open, setOpen] = useState(false);

  return (
    <DropdownMenu
      onOpenChange={setOpen}
      open={open}
    >
      <DropdownMenuTrigger asChild>
        <Button
          className="h-min p-0 data-[state=open]:text-foreground gap-1 p-1 hover:bg-inherit text-muted-foreground hover:text-foreground"
          variant="outline"
        >
          <IncidentStatus status={status} />
          <ChevronDown className={cn('h-4 w-4', open && 'rotate-180')} />
          <span className="sr-only">Open menu</span>
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent
        align="center"
        className="w-32"
      >
        <DropdownMenuGroup
          onClick={(event) => {
            event.preventDefault();
            event.stopPropagation();
          }}
        >
          <IncidentStatusMenuRadioGroup
            incidentId={incidentId}
            status={status}
          />
        </DropdownMenuGroup>
      </DropdownMenuContent>
    </DropdownMenu>
  );
};

export { ChangeIncidentStatusMenu };
