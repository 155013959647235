import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbList,
  BreadcrumbPage,
  BreadcrumbSeparator,
} from '@/components/ui/breadcrumb';
import { settingsNavItems } from '@/features/Organization/components/OrganizationSettingsLayout';
import { useAppStore } from '@/stores';
import { Link, useLocation } from 'react-router-dom';

// eslint-disable-next-line complexity
const ApplicationBreadcrumbs = () => {
  const pathname = useLocation().pathname;
  const search = useLocation().search;
  const activeMembership = useAppStore((state) => state.activeMembership);

  if (!activeMembership) {
    return null;
  }

  const [, root, ...rest] = pathname.split('/');

  return (
    <Breadcrumb className="hidden md:flex">
      <BreadcrumbList>
        {root !== 'account' && (
          <BreadcrumbItem>
            <BreadcrumbLink asChild>
              <Link to="/home">{activeMembership.name}</Link>
            </BreadcrumbLink>
          </BreadcrumbItem>
        )}

        {root === 'account' && (
          <>
            <BreadcrumbItem>
              <BreadcrumbLink asChild>
                <Link to="/account">My Account</Link>
              </BreadcrumbLink>
            </BreadcrumbItem>

            {rest[0] === 'profile' && (
              <>
                <BreadcrumbSeparator />
                <BreadcrumbItem>
                  <BreadcrumbPage>Manage Profile</BreadcrumbPage>
                </BreadcrumbItem>
              </>
            )}
            {rest[0] === 'contact' && (
              <>
                <BreadcrumbSeparator />
                <BreadcrumbItem>
                  <BreadcrumbPage>Manage Contact Details</BreadcrumbPage>
                </BreadcrumbItem>
              </>
            )}
            {rest[0] === 'memberships' && (
              <>
                <BreadcrumbSeparator />
                <BreadcrumbItem>
                  <BreadcrumbPage>Manage Memberships</BreadcrumbPage>
                </BreadcrumbItem>
              </>
            )}
            {rest[0] === 'notifications' && (
              <>
                <BreadcrumbSeparator />
                <BreadcrumbItem>
                  <BreadcrumbPage>View All Notifications</BreadcrumbPage>
                </BreadcrumbItem>
              </>
            )}
            {rest[0] === 'close' && (
              <>
                <BreadcrumbSeparator />
                <BreadcrumbItem>
                  <BreadcrumbPage>Close</BreadcrumbPage>
                </BreadcrumbItem>
              </>
            )}
          </>
        )}

        {root === 'home' && (
          <>
            <BreadcrumbSeparator />
            <BreadcrumbItem>
              <BreadcrumbPage>Dashboard</BreadcrumbPage>
            </BreadcrumbItem>
          </>
        )}

        {root === 'check-in' && (
          <>
            <BreadcrumbSeparator />
            <BreadcrumbItem>
              <BreadcrumbLink asChild>
                <Link to="/check-in">Check In</Link>
              </BreadcrumbLink>
            </BreadcrumbItem>
            {pathname === '/check-in' ? (
              <>
                <BreadcrumbSeparator />
                <BreadcrumbItem>
                  <BreadcrumbPage>All Check In's</BreadcrumbPage>
                </BreadcrumbItem>
              </>
            ) : (
              <>
                <BreadcrumbSeparator />
                <BreadcrumbItem>
                  <BreadcrumbPage>View Details</BreadcrumbPage>
                </BreadcrumbItem>
              </>
            )}
          </>
        )}

        {root === 'check-in-reports' && (
          <>
            <BreadcrumbSeparator />
            <BreadcrumbItem>
              <BreadcrumbLink asChild>
                <Link to="/check-in-reports">Check In Reports</Link>
              </BreadcrumbLink>
            </BreadcrumbItem>
            {rest[0] === undefined && (
              <>
                <BreadcrumbSeparator />
                <BreadcrumbItem>
                  <BreadcrumbPage>All Check In Reports</BreadcrumbPage>
                </BreadcrumbItem>
              </>
            )}
            {rest[0] === 'new' && (
              <>
                <BreadcrumbSeparator />
                <BreadcrumbItem>
                  <BreadcrumbPage>New</BreadcrumbPage>
                </BreadcrumbItem>
              </>
            )}
            {rest[0]?.length === 26 && (
              <>
                <BreadcrumbSeparator />
                <BreadcrumbItem>
                  <BreadcrumbPage>View Details</BreadcrumbPage>
                </BreadcrumbItem>
              </>
            )}
          </>
        )}

        {root === 'guardme' && (
          <>
            <BreadcrumbSeparator />
            <BreadcrumbItem>
              <BreadcrumbLink asChild>
                <Link to="/guardme">GuardMe</Link>
              </BreadcrumbLink>
            </BreadcrumbItem>
            {pathname === '/guardme' ? (
              <>
                <BreadcrumbSeparator />
                <BreadcrumbItem>
                  <BreadcrumbPage>All Sessions</BreadcrumbPage>
                </BreadcrumbItem>
              </>
            ) : (
              <>
                <BreadcrumbSeparator />
                <BreadcrumbItem>
                  <BreadcrumbPage>Session Details</BreadcrumbPage>
                </BreadcrumbItem>
              </>
            )}
          </>
        )}

        {root === 'incidents' && (
          <>
            <BreadcrumbSeparator />
            <BreadcrumbItem>
              <BreadcrumbLink asChild>
                <Link to="/incidents">Incidents</Link>
              </BreadcrumbLink>
            </BreadcrumbItem>
            {rest[0] === undefined && (
              <>
                <BreadcrumbSeparator />
                <BreadcrumbItem>
                  {search.includes('view=submittedByMe') ? (
                    <BreadcrumbPage>My Incidents</BreadcrumbPage>
                  ) : search.includes('view=assignedToMyGroup') ? (
                    <BreadcrumbPage>Assigned to My Group</BreadcrumbPage>
                  ) : (
                    <BreadcrumbPage>All Incidents</BreadcrumbPage>
                  )}
                </BreadcrumbItem>
              </>
            )}
            {rest[0] === 'new' && (
              <>
                <BreadcrumbSeparator />
                <BreadcrumbItem>
                  <BreadcrumbPage>New</BreadcrumbPage>
                </BreadcrumbItem>
              </>
            )}
            {rest[0]?.length === 26 && (
              <>
                <BreadcrumbSeparator />
                <BreadcrumbItem>
                  <BreadcrumbPage>Incident Details</BreadcrumbPage>
                </BreadcrumbItem>
              </>
            )}
          </>
        )}

        {root === 'members' && (
          <>
            <BreadcrumbSeparator />
            <BreadcrumbItem>
              <BreadcrumbLink asChild>
                <Link to="/members">Members</Link>
              </BreadcrumbLink>
            </BreadcrumbItem>
            {rest[0] === undefined && (
              <>
                <BreadcrumbSeparator />
                <BreadcrumbItem>
                  <BreadcrumbPage>Overview</BreadcrumbPage>
                </BreadcrumbItem>
              </>
            )}
            {rest[0] === 'invitations' && (
              <>
                <BreadcrumbSeparator />
                <BreadcrumbItem>
                  <BreadcrumbPage>Invitation Details</BreadcrumbPage>
                </BreadcrumbItem>
              </>
            )}
            {rest[0] === 'requests' && (
              <>
                <BreadcrumbSeparator />
                <BreadcrumbItem>
                  <BreadcrumbPage>Membership Request Details</BreadcrumbPage>
                </BreadcrumbItem>
              </>
            )}
            {rest[0] === 'groups' && (
              <>
                <BreadcrumbSeparator />
                <BreadcrumbItem>
                  <BreadcrumbPage>Group Details</BreadcrumbPage>
                </BreadcrumbItem>
              </>
            )}
            {rest[0]?.length === 26 && (
              <>
                <BreadcrumbSeparator />
                <BreadcrumbItem>
                  <BreadcrumbPage>Member Details</BreadcrumbPage>
                </BreadcrumbItem>
              </>
            )}
          </>
        )}

        {root === 'settings' && (
          <>
            <BreadcrumbSeparator />
            <BreadcrumbItem>
              <BreadcrumbLink asChild>
                <Link to="/settings">Settings</Link>
              </BreadcrumbLink>
            </BreadcrumbItem>
            <BreadcrumbSeparator />
            <BreadcrumbItem>
              {settingsNavItems.map((item) => {
                if (pathname === item.to) {
                  return (
                    <BreadcrumbPage key={item.to}>{item.title}</BreadcrumbPage>
                  );
                }

                return null;
              })}
            </BreadcrumbItem>
          </>
        )}
      </BreadcrumbList>
    </Breadcrumb>
  );
};

export { ApplicationBreadcrumbs };
